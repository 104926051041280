import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/default"

import AudioPlayer from "../../../components/audio-player"
import Breadcrumb from "../../../components/breadcrumb"
import Column from "../../../components/column"
import Columns from "../../../components/columns"
import Constrain from "../../../components/constrain"
import HyperAussicht from "../../../content/shared/hyper-aussicht"
import HyperBibliothekar from "../../../content/shared/hyper-bibliothekar"
import HyperFamilie from "../../../content/shared/hyper-familie"
import HyperFreunde from "../../../content/shared/hyper-freunde"
import HyperKrank from "../../../content/shared/hyper-krank"
import HyperOrte from "../../../content/shared/hyper-orte"
import HyperPfarrer from "../../../content/shared/hyper-pfarrer"
import HyperTurm from "../../../content/shared/hyper-turm"
import HyperVerliebt from "../../../content/shared/hyper-verliebt"
import Image from "../../../components/image"
import PageTitle from "../../../components/page-title"
import Paragraph from "../../../components/paragraph"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"

const LeichteSpracheUeberHoelderlin = () => {
  const data = useStaticQuery(graphql`
    query LeichteSpracheUeberHoelderlinIndexQuery {
      portraitRahmen: file(
        relativePath: { eq: "sprachen/portrait-rahmen.png" }
      ) {
        ...smallImage
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Mehr über Friedrich Hölderlin"
        description="Hölderlin wird am 20. März 1770 geboren. Sein ganzer Name ist Johann Christian Friedrich Hölderlin. Er kommt in der Stadt Lauffen am Neckar zur Welt."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Leichte Sprache",
                link: "/leichte-sprache",
              },
              {
                title: "Über Hölderlin",
                link: "/leichte-sprache/ueber-hoelderlin",
              },
            ]}
          />
          <PageTitle>Mehr über Friedrich Hölderlin</PageTitle>
          <Columns
            collapse={[true, false]}
            reverse={[false, true]}
            alignY="center"
            space={[6, 12]}
          >
            <Column>
              <Stack>
                <Paragraph>
                  Hölderlin wird am 20. März 1770 geboren. <br />
                  Sein ganzer Name ist <br />
                  Johann Christian Friedrich Hölderlin. <br />
                  Er kommt in der Stadt Lauffen am Neckar zur Welt.
                </Paragraph>
                <AudioPlayer src="leichte-sprache/02-hoelderlin" />
              </Stack>
            </Column>
            <Column width="2/6">
              <Image
                image={data.portraitRahmen.childImageSharp.gatsbyImageData}
                alt="Ein Ölgemälde zeigt Friedrich Hölderlin mit langen, hellgrauen Haaren. Er trägt ein Hemd und ein Jackett"
                attribution="DLA Marbach"
              />
            </Column>
          </Columns>
        </Stack>

        <Constrain>
          <Stack space={6}>
            <HyperFamilie />
            <HyperPfarrer />
            <HyperFreunde />
            <HyperVerliebt />
            <HyperBibliothekar />
            <HyperKrank />
            <HyperTurm />
            <HyperAussicht />
            <HyperOrte />
          </Stack>
        </Constrain>
      </Stack>
    </Layout>
  )
}

export default LeichteSpracheUeberHoelderlin
